import { Link, Picture } from '@/components/utilities';

export default function NavigationLogo({ href = '/' }) {
  return (
    <Link
      block
      href={href}
      className="flex items-center gap-2 lg:gap-6 px-4 md:px-8 py-2 opacity-90 hover:opacity-100 flex-shrink-0">
      {/* <img
        src="https://ittybit-app.ittybitcdn.com/med_496nd865Tr6FMb5F/ittybit-icon.png"
        width="96"
        height="96"
        className={`relative h-6 w-6 lg:h-10 lg:w-10`}
        alt=""
        priority
      /> */}
      <img
        // src="https://ittybit-app.ittybitcdn.com/med_rbcpu5q6hdrp/original"
        src="https://ittybit-app.ittybitcdn.com/med_497obJ50a6oWP8F9/ittybit-logo_2x.png"
        width="300"
        height="100"
        className={`relative h-8 w-auto lg:h-12 lg:w-auto top-1 antialiased`}
        alt="ittybit"
        priority
      />
    </Link>
  );
}
