'use client';

import { usePathname } from 'next/navigation';
import { Icon, Link } from '@/components/utilities';

export default function NavigationLink({
  href,
  icon,
  text,
  iconPosition = 'right',
  iconColor = 'text-brand-purple',
}) {
  const pathname = usePathname();
  return (
    <Link
      href={href}
      className={`flex items-center  ${
        pathname.includes(href) ? 'text-1' : 'text-3 hover:text-1'
      }`}
      title={text}>
      {iconPosition === 'left' ? (
        <div
          className={`rounded-sm p-1 ${iconColor.replace(
            'text-',
            'bg-',
          )}/20 mr-2 lg:mr-3`}>
          <Icon icon={icon} className={`w-4 h-4 lg:w-6 lg:h-6 ${iconColor}`} />
        </div>
      ) : null}
      <span className="hidden lg:block max-w-[15rem] whitespace-nowrap text-ellipsis overflow-hidden text-base lg:text-lg leading-5 lg:leading-6 font-medium">
        {text}
      </span>
      {iconPosition === 'right' ? (
        <div
          className={`rounded-sm p-1 ${iconColor.replace(
            'text-',
            'bg-',
          )}/20 ml-2 lg:ml-3`}>
          <Icon icon={icon} className={`w-4 h-4 lg:w-6 lg:h-6 ${iconColor}`} />
        </div>
      ) : null}
    </Link>
  );
}
