'use client';

import { useState } from 'react';
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';

import { Icon, Link } from '@/components/utilities';
import { usePathname } from 'next/navigation';
import { apiMobileMenuItems, marketingMenuItems } from '.';

export default function PublicMobileMenu({ items, user }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const pathname = usePathname();

  function toggleMenu(e) {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  }
  return (
    <>
      <button
        type="button"
        className="group p-2 xl:hidden"
        onClick={toggleMenu}>
        <span className="sr-only">Open menu</span>
        <Icon
          icon="menu"
          className="h-8 w-8 text-white text-opacity-60 group-hover:text-opacity-100"
          aria-hidden="true"
        />
      </button>

      {/* Vertical version for narrow screens */}
      <Transition show={menuOpen}>
        <Dialog className="relative z-50 xl:hidden" onClose={toggleMenu}>
          <TransitionChild
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-dark-100/80" />
          </TransitionChild>

          <div className="fixed inset-0 flex">
            <TransitionChild
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-4">
                    <button
                      type="button"
                      className="group -m-2 p-2"
                      onClick={toggleMenu}>
                      <span className="sr-only">Close sidebar</span>
                      <Icon
                        icon="close"
                        className="h-6 w-6 text-white text-opacity-60 group-hover:text-opacity-100"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </TransitionChild>

                <ul className="xl:hidden flex grow flex-col items-stretch justify-start h-full overflow-auto bg-dark-200 py-4">
                  <ul className="pb-8 w-full">
                    {items
                      ?.filter(i => i.primary)
                      .map(({ href, text, icon }) => (
                        <li
                          key={href}
                          onClick={() => setMenuOpen(false)}
                          className="py-2">
                          <Link
                            href={href}
                            className={`flex items-center px-4 md:px-6 py-2 ${
                              pathname.includes(href)
                                ? 'text-1'
                                : 'text-3 hover:text-1'
                            }`}
                            title={text}>
                            <Icon
                              icon={icon}
                              className="w-6 h-6 mr-4 md:mr-6 text-brand-purple"
                            />
                            <span className="max-w-[15rem] whitespace-nowrap text-ellipsis overflow-hidden text-sm md:text-base leading-6 font-code">
                              {text}
                            </span>
                          </Link>
                          {pathname.startsWith(`/docs/api`) &&
                          href === '/docs/api' ? (
                            <ul className="flex flex-col items-stretch justify-start">
                              {apiMobileMenuItems.map(
                                ({ slug, title, icon, iconColor }) => (
                                  <li
                                    key={slug}
                                    onClick={() => setMenuOpen(false)}>
                                    <Link
                                      href={`/docs/api/${slug}`}
                                      className={`flex items-center px-4 md:px-6 py-2 ${
                                        pathname === `/docs/api/${slug}`
                                          ? 'text-1'
                                          : 'text-3 hover:text-1'
                                      }`}
                                      title={title}>
                                      <Icon
                                        icon={icon}
                                        className={`w-5 h-5 mr-5 md:mr-7 ${iconColor}`}
                                      />
                                      <span className="max-w-[15rem] whitespace-nowrap text-ellipsis overflow-hidden text-xs md:text-sm leading-6 font-code">
                                        {title}
                                      </span>
                                    </Link>
                                  </li>
                                ),
                              )}
                            </ul>
                          ) : null}
                          {pathname.startsWith(`/features`) &&
                          href === '/features' ? (
                            <ul className="flex flex-col items-stretch justify-start">
                              {marketingMenuItems.map(
                                ({ slug, title, icon, iconColor }) => (
                                  <li
                                    key={slug}
                                    onClick={() => setMenuOpen(false)}>
                                    <Link
                                      href={`/features/${slug}`}
                                      className={`flex items-center px-4 md:px-6 py-2 ${
                                        pathname.includes(slug)
                                          ? 'text-1'
                                          : 'text-3 hover:text-1'
                                      }`}
                                      title={title}>
                                      <Icon
                                        icon={icon}
                                        className={`w-5 h-5 mr-5 md:mr-7 ${iconColor}`}
                                      />
                                      <span className="max-w-[15rem] whitespace-nowrap text-ellipsis overflow-hidden text-xs md:text-sm leading-6 font-code">
                                        {title}
                                      </span>
                                    </Link>
                                  </li>
                                ),
                              )}
                            </ul>
                          ) : null}
                        </li>
                      ))}
                  </ul>
                  <ul className="mt-auto w-full">
                    {items
                      ?.filter(i => i.secondary)
                      .map(({ href, text, icon }) => (
                        <li key={href} onClick={() => setMenuOpen(false)}>
                          <Link
                            href={href}
                            className={`flex items-center px-4 md:px-6 py-2 ${
                              pathname === href
                                ? 'text-1'
                                : 'text-3 hover:text-1'
                            }`}
                            title={text}>
                            <Icon
                              icon={icon}
                              className="w-5 h-5 mr-5 md:mr-7 text-brand-purple"
                            />
                            <span className="max-w-[15rem] whitespace-nowrap text-ellipsis overflow-hidden text-xs md:text-sm leading-6 font-code">
                              {text}
                            </span>
                          </Link>
                        </li>
                      ))}
                    {!user ? (
                      <div className="flex flex-col items-stretch w-full px-4 md:px-6 py-2 space-y-4 md:space-y-6">
                        <Link
                          href="/login"
                          className="flex w-full h-8 items-center justify-center bg-white bg-opacity-5 hover:bg-opacity-10 text-1 font-code border-none outline-none rounded-sm text-sm leading-6">
                          <span>Log in</span>
                        </Link>
                        <Link
                          href="/register"
                          className="flex w-full h-8 items-center justify-center  bg-brand-500 hover:bg-brand-300 text-1 font-code border-none outline-none rounded-sm text-sm leading-6">
                          <span>Create Account</span>
                        </Link>
                      </div>
                    ) : null}
                  </ul>
                </ul>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
